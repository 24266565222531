import Image1 from './../assets/images/calculator.png'
import Image2 from './../assets/images/abacus.png'
import Image3 from './../assets/images/games.png'

const data = [
  {
    title: 'Machine Learning, Andrew Ng',
    website: 'https://www.coursera.org/learn/machine-learning/',
    description: `Andrew Ng`,
    location: '../../ml',
    image: Image1
  },
]

export default data
